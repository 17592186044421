<template>
    <div>
        <relationTournamentList :game_id="game_id"></relationTournamentList>
    </div>
</template>
<script>

import relationTournamentList from '@/components/relation/games/tournament/list.vue';

export default {
    components: {
        relationTournamentList
    },
    data () {
        let self = (window.x = this);
        return {
            game_id: 1,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
